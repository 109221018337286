@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Gill Sans", sans-serif;
  src: url("../public/Fonts/GillSans-Bold.ttf");
}
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    width: 0;
}

button[aria-label="close"] {
    color: white;
  }

/* Hide scrollbar for IE, Edge, and Firefox */
html {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"].no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-datepicker-wrapper,
.chart {
    width: 100% !important;
}
.react-datepicker__input-container input {
    width: 100%;
    max-width: 100%;
    min-height: 38.34px !important;
        height: 38.34px !important;
}
.adminBackground {
    background-image: url("./Assets/Imgs/laundry2.jpg");
    background-repeat: no-repeat;
}
.innerTable {
    width: calc(100vw - 24px);
}
 
.swiper {
    width: 100px !important;
}

.swiper-slide {
    padding: 0 0 15px 0 !important;
}

.swiper-pagination {
    bottom: -5px !important;
}